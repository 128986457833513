
*{
  box-sizing: border-box;
}

html{
  font-family: “Microsoft YaHei”;
  position: relative;
  min-height: 100%;
}

body{
  margin: 0 0 2.5rem;
  padding: 0;
}

.navbar-top{
    background-color: $main_blue;
    position: absolute;
    border: 0;
    top: 0;
    width: 100%;
    z-index: 200;

    .navbar-header{
        text-align: center;
        .res-navbar{
            margin: -2px;
            padding: 0;
            width: auto; 
            display: inline-block;
            position: relative;
            left: -4rem;
            @media(max-width: $mobile_width){
                left: 0;
            }
            .logo{
                margin-right: 5rem;
                padding: 0 5rem;
                @media(max-width: 1100px){
                    margin: 0;
                    padding: 0 4rem;
                }
                img{
                    width: 2.875rem;
                    height: 3.75rem;
                    vertical-align: middle;
                 }
            }
            a{
                li{
                    font-size: 1.3rem;
                    color: white;
                    list-style-type: none;
                    float: left;
                    padding: 0 2.19rem;
                    height: 100%;
                    line-height: 5rem;

                    transition-property: background-color;
                    transition-duration: .3s;
                    transition-timing-function: ease-in;
                    transition-delay: 0s;

                    &:hover{
                        background-color: rgba(0,0,0,0.1);
                    }
                }
                .active{
                    background-color: white;
                    color: $main_blue;
                    &:hover{
                        background-color: rgba(255,255,255,0.8);
                    }
                }

            }
            @media (max-width: $mobile_width) {
                .logo{
                    display: none;
                }
                a{
                    li{
                        font-size: 1.3rem;
                        float: left;
                        padding: 0 1rem;
                        height: 100%;
                        line-height: 3rem;

                    }
                }
            }

        }
    }
}
 .page-content{
     margin-top: 4.8rem;
     margin-bottom: 9.9rem;
     @media (max-width: 600px) {
         margin-top: 2.8rem;
     }
 }
.site-footer{
    background-color: $main_deep_blue;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10rem;
    .wrapper{
        text-align: center;
        color: white;
        .copyright{
            margin-top: 1.875rem;
            font-size: 1.5rem;
        }
        .icp{
            margin-top: 1.25rem;
            font-size: 1.125rem;
        }
        .contact{
            margin-top: 1rem;
            font-size: 1rem;
            span{
                margin: 0 1rem;
            }
        }
    }
}