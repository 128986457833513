.icon-triangle-white{
  display: inline-block;
  height: 17px;
  width: 15px;
  background: url('#{$qn_url}/icons.png') 0 0;
}
.icon-triangle-blue{
  height: 17px;
  width: 15px;
  background: url('#{$qn_url}/icons.png') 0 -16px;
}
.icon-phone{
  display: inline-block;
  height: 22px;
  width: 20px;
  background: url('#{$qn_url}/icons.png') 0 -33px;
}