@charset "utf-8";


$main_blue: rgba(0,194,201,1);
$main_deep_blue: rgba(0,162,168,1);
$mobile_width: 599px;
$qn_url: 'http://web-assets.qiniu.didikon.com';

.clearfix{overflow:auto;_height:1%}

@media(max-width: $mobile_width){
  html{
    font-size: 10px;
  }
  .show-in-pc{
    display: none;
  }
  .show-in-mobile{
    display: block;
  }
}

@media(min-width: 600px) and (max-width: 999px){
  html{
    font-size: 10px;
  }
}
@media(min-width: 1000px) and ( max-width: 1399px){
  html{
    font-size: 11px;
  }
}
@media(min-width: 1400px) and ( max-width: 1599px){
  html{
    font-size: 13px;
  }
}
@media(min-width: 1600px) and ( max-width: 1799px){
  html{
    font-size: 14px;
  }
}
@media(min-width: 1799px) and ( max-width: 1999px){
  html{
    font-size: 16px;
  }
}
@media(min-width: 2000px){
  html{
    font-size: 18px;
  }
}

@import
  "layer",
  "icons",
  "index",
  "product",
  "case",
  "about",
  "mutual_adi"
;
