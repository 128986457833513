.about{
  .group1{
    text-align: center;
    .container{
      display: flex;
      justify-content: center;
      align-items: center;
      .pictures{
        display: flex;
        .min{
          border: 1px solid #efefef;
          background: url('#{$qn_url}/天府菁蓉大厦.jpg') center;
          background-size: cover;
        }
      }
      .desc{
        width: 24.375rem;
        height: 24.375rem;
        display: flex;
        justify-content: flex-start;
        position: relative;
        //border: 1px solid blue;

        .text{
          width: 17.5rem;
          height: 20.5rem;
          z-index: 3;
          //border: 1px solid red;
          background: white;
          position: relative;
          .title{
            text-align: left;
            padding: 1rem 0;
            margin-top: 0.2rem;
            display: flex;
            align-items: center;
            .icon-triangle-blue{
              margin-right: 0.8rem;
            }
            span{
              font-size: 1.4rem;
            }
          }
          .content{
            //border: 1px solid black;
            position: relative;
            width: 21.38rem;
            p{
              text-align: left;
              text-indent: 2em;
              font-size: 1.125rem;
              color: #333;
              line-height: 2.17rem;
            }
          }

        }
        .board{
          position: absolute;
          border: 0.25rem solid $main_blue;
          width: 20rem;
          height: 20.38rem;
          left: 3rem;
          bottom: 1.5rem;
        }
      }
      @media(min-width: $mobile_width){
        padding-top:  10.75rem;
        padding-bottom: 10.75rem;
        .pictures{
          position: relative;
          width: 32.875rem;
          height: 24rem;
          justify-content: flex-start;
          .big{
            border: 1px solid #efefef;
            width: 23.75rem;
            height: 17.5rem;
            background: url('#{$qn_url}/天府广场.jpg') center;
            background-size: cover;
          }
          .min{
            position: absolute;
            bottom: 0;
            width: 17.8rem;
            height: 12rem;
            left: 15rem;
          }
        }
        .desc{
          margin-left: 10.4rem;
        }
      }

      @media(max-width: $mobile_width){
        flex-direction: column;
        padding-top:  4rem;

        .pictures{
          justify-content: center;
          .min{
            border: 1px solid #efefef;
            width: 21.6rem;
            height: 16.2rem;
          }
        }
        .desc{
          margin-left: 2rem;
        }
      }
    }

  }
  .group2{
    background-color: $main_blue;

    .container{
      display: flex;
      justify-content: center;
      height: 25rem;
      .desc{
        color: white;
        font-size: 1.125rem;
        text-indent: 2em;
        line-height: 2.25rem;
        font-weight: 500;
      }
      .pictures{

        display: flex;

      }
      @media (min-width: $mobile_width) {
        .desc{
          padding: 2rem;
          width: 25.7rem;

        }
        .pictures{
          margin-left: 15.625rem;
          position: relative;
          top: -6rem;
          justify-content: flex-start;
          width: 32.875rem;
          height: 28.25rem;
          img:first-child{
            width: 25rem;
            height: 16.8rem;
            z-index: 3;
          }
          img:last-child{
            position: absolute;
            width: 23.0rem;
            height: 17.1rem;
            bottom: 0;
            right: 0;
          }
        }
      }
      @media(max-width: $mobile_width){
        flex-direction: column;
        height: 45rem;
        .desc{
          padding: 0 2rem;
        }
        .pictures{
          justify-content: center;
          margin-top: 1rem;
          img:last-child{
            width: 25.26rem;
            height: 21.54rem;

          }
        }
      }
    }
  }

  .group3{
    height: 48rem;

    .container{

      padding: 9.125rem 0;
      display: flex;
      justify-content: center;

      .desc{
        width: 24.375rem;
        height: 22rem;
        display: flex;
        justify-content: flex-start;
        position: relative;
        .text{
          width: 17.5rem;
          height: 18rem;
          z-index: 3;
          //border: 1px solid red;
          background: white;
          position: relative;
          left: 3rem;
          .content{

            //border: 1px solid black;
            position: relative;
            width: 20rem;
            text-align: left;
            text-indent: 2em;
            font-size: 1.125rem;
            color: #333;
            line-height: 2.17rem;
            padding: 1rem;

          }
        }
        .board{
          position: absolute;
          border: 0.25rem solid $main_blue;
          width: 18.75rem;
          height: 17.5rem;
          bottom: 0;
          left: 0;
        }
      }
      @media(min-width: $mobile_width){
        .pictures{
          img{
            width: 39.37rem;
            height: 23.6rem;
          }
        }
        .desc{
          margin-left: 8.75rem;

        }
      }
      @media(max-width: $mobile_width){
        flex-direction: column;
        align-items: center;
        .pictures{
          img{
            width: 23.625rem;
            height: 14.25rem;

          }
        }
      }
    }
  }
}