.mutual_adi{
  display: flex;
  justify-content: center;
  .container{
    header{
      display: flex;
      justify-content: center;
    }

    section{
      height: 14.25rem;
      margin-top: 3.75rem;
      font-size: 1.45rem;
      .desc{
        display: flex;
        background-color: $main_blue;
        align-items: center;
        color: white;
      }
      .contact{
        border: 1px solid #e5e5e5;
        border-top: 0;
        display: flex;
        align-items: center;
        color: #333;
        .mobile{
          color: $main_blue
        }
      }
    }
    @media(min-width: $mobile_width){
      padding: 5.2rem 0;
      width: 63.75rem;
      header{
        margin-bottom: 1.25rem;
        img{
          width: 28.125rem;
          height: 18.75rem;
        }
        .desc{
          margin-left: 8.38rem;
          color: #333;
          .title{
            text-align: left;
            display: flex;
            align-items: center;
            font-size: 1.6rem;
            .icon-triangle-blue{
              margin-right: 0.8rem;
            }
          }
          .content{
            padding-top: 1rem;
            line-height: 2rem;
            font-size: 1.2rem;
          }
        }
      }
      section{
        .desc{
          padding: 2.5rem;
          height: 9.375rem;

        }
        .contact{
          padding: 2.5rem;
          height: 4.75rem;
        }
      }
    }
    @media (max-width: $mobile_width) {
      margin-top: 3rem;
      padding: 2rem;
      header{
        margin-bottom: 1.25rem;
        flex-direction: column;
        align-items: center;
        img{
          width: 22.5rem;
          height: 15rem;
        }
        .desc{
          margin-top: 2rem;
          color: #333;
          .title{
            text-align: left;
            display: flex;
            align-items: center;
            font-size: 1.6rem;
            .icon-triangle-blue{
              margin-right: 0.8rem;
            }
          }
          .content{
            padding-top: 1rem;
            line-height: 2rem;
            font-size: 1.2rem;
          }
        }
      }
      section{
        .desc{
          padding: 1.5rem;
        }
        .contact{
          padding: 0 1rem ;
          height: 4.75rem;
          flex-wrap: wrap;
        }
      }
    }
  }
}