.product{
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner{
    width: 100%;
    min-height: 18.75rem;
    background: url('#{$qn_url}/product-banner.jpg') no-repeat center;
    background-size: cover;
    text-align: center;
    .text{
      margin: 6.1rem auto 0;
      width: 42.94rem;
      height: 5.812rem;
      background: url("#{$qn_url}/intelligent_community.png") no-repeat center;
      background-size: contain;
    }
  }
  .container{
    padding-top: 1.25rem;
    padding-bottom: 5rem;
    .item{
      display: flex;
      flex-direction: row;
      margin-top: 3.75rem;

      .need_board{
        border: 1px solid #eee;
      }
      .desc{
        background: $main_blue;
        color: white;
        font-size: 1.2rem;
        line-height: 2.125rem;
        .title {
          font-size: 1.7rem;
          margin-bottom: 2.5rem;
        }
      }
      @media(min-width: $mobile_width){
        img{
          height: 18.75rem;
        }
        .desc{
          padding: 2.5rem 5.625rem;
          height: 18.75rem;
          width: 45rem;
        }
      }
    }
  }

  @media(max-width: $mobile_width){
    .banner{
      min-height: 12.75rem;
      .text{
        width: 21.47rem;
        height: 2.9rem;
      }
    }
    .container{
      display: flex;
      flex-direction: column;
      .item{
        display: flex;
        background: $main_blue;
        img{
          height: 8rem;
          width: auto;
        }
        .desc{
          padding: 1rem;
          background: $main_blue;
          color: white;
          font-size: 1.2rem;
          line-height: 2.125rem;
          .title {
            font-size: 1.7rem;
            margin-bottom: 2.5rem;
          }
        }
      }
    }
  }
}