.case{
  .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    .group{
      nav{
        display: flex;
        align-items: center;
        background-color: $main_blue;
        height: 3.125rem;
        i{
          margin: 1rem;
        }
        .title{
          color: white;
          font-size: 1.25rem;
        }
      }
      .item{
        display: flex;
        .card{
          border: 1px solid #eee;
          border-collapse: collapse;
          width: 25rem;
          height: 31.25rem;
          padding: 1.25rem;
          background-color: white;
          transition: all .2s ease-in-out;
          box-shadow: 2px 2px 5px rgba(88, 88, 88, 0.6);
          img{
            width: 22.5rem;
            height: 20rem;

          }
          &:hover{
            //transition: all .2s ease-in-out;
            //-webkit-transform: scale(1.02);
            //-ms-transform: scale(1.02);
            //transform: scale(1.02);
            //zoom: 1.02;
            transform: rotate(10deg);
            box-shadow: 2px 2px 5px rgba(88, 88, 88, 0.6);
          }
          .desc{
            padding: 0.1rem 0.5rem;
            .title{
              font-size: 1.5rem;
              font-weight: 500;
              color: #353535;
            }
            .content{
              font-size: 1.125rem;
              color: #555555;
            }
          }
        }

      }
    }
    @media(min-width: $mobile_width){
      padding: 3.75rem;
      .group{
        width: 75rem;
        nav{
          width: 75rem;
          margin: 2.19rem 0;

        }
        .item{
          flex-direction: row;

        }
      }
    }
    @media(max-width: $mobile_width){
      padding: 2rem;
      .group{
        //width: 75rem;
        nav{
          width: 100%;
          margin-top: 2rem;
        }
        .item{
          flex-direction: column;
          .card{
            margin-top: 1rem;
          }
        }
      }
    }
  }
}