.home{
  width: 100%;

  .swiper-container {
    width: 100%;
    .swiper-wrapper{
      .swiper-slide{
        .banner{
          background-size: cover;
          height: 62.5rem;
          text-align: center;
          img{
            position: relative;
          }
        }
        .banner1{
          background: url("#{$qn_url}/banner1.jpg") no-repeat top;
          background-size: cover;
          img{
            margin-top: 16rem;
            right: -4.375rem;
            width: 63.69rem;
            height: 29.44rem;
          }
        }
        .banner2{
          background: url("#{$qn_url}/banner2.jpg") no-repeat center;
          background-size: cover;
          @media (min-width: $mobile_width ) {
            img{
              margin-top: 13rem;
              right: -9rem;
              width: 35.8rem;
              height: 27.44rem;
            }
          }
        }
        .banner3{
          background: url("#{$qn_url}/banner3.jpg") no-repeat center;
          background-size: cover;
          @media (min-width: $mobile_width ) {
            img{
              top: -10rem;
              left: -13rem;
              width: 31.25rem;
              height: 29.25rem;
            }
            .right-text{
              position: relative;
              display: inline-block;
              margin-top: 38.25rem;
              color: white;
              font-size: 1.875rem;
              right: -12rem;
              font-weight: 500;
            }
          }
        }
//手机端样式
        @media(max-width: $mobile_width){
          .banner{
            height: 45rem;
          }
          .banner1{
            display: flex;
            justify-content: center;
            align-items: center;
            .banner1_01{
              position: absolute;
              background: url('#{$qn_url}/banner1_01.png') no-repeat;
              background-size: contain;
              width: 12rem;
              height: 13rem;
              top: 17rem;
              left: 1rem;
            }
            .banner1_02{
              position: absolute;
              background: url('#{$qn_url}/banner1_02.png') no-repeat;
              background-size: contain;
              width: 16.375rem;
              height: 14.72rem;
              top: 14rem;
              right: 1rem;
            }
          }
          .banner2{
            display: flex;
            justify-content: center;
            align-items: center;
            img{
              position: absolute;
              top: 10rem;
              right: 3rem;
              width: 18rem;
              height: 13.72rem;
            }
          }
          .banner3{
            display: flex;
            justify-content: center;
            img{
              position: absolute;
              top: 14rem;
              //left: 1rem;
              width: 15.625rem;
              height: 14.625rem;
            }
            .right-text{
              position: absolute;
              bottom: 9rem;
              color: white;
              font-size: 1.2rem;
              font-weight: 500;
            }
          }

        }
      }
      
    }
  }
}
 .swiper-pagination{
    .swiper-pagination-bullet{
      width: 17px;
      height: 17px;
      opacity: 1;
      background: white;

    }
    .swiper-pagination-bullet-active{
      width: 40px;
      height: 17px;
      background: $main_blue;
       border-radius: 15px;
    }
}
